import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="The Soogs"
      heroSubTitle="The Feel-Good Band of Now"
      heroBackgroundImage="/images/the-soogs-atlanta-ga.jpg"
    >
      <SEO
        title="The Soogs | Indie Pop from Atlanta, Georgia"
        ogDescription="The Soogs have been making indie pop in Atlanta, GA on and off since 1991. Described as “The Pixies meet The Partridge Family."
        image="https://thesoogs.com/images/the-soogs-indie-pop-atlanta.jpg"
        url="https://thesoogs.com"
      />
      <TextBlock
        textPadded
        textLeft={
          <div id="about">
            <p>
              The Soogs have played in and around Atlanta on and off since 1991. In 2016 they started making records with <a href="https://jimmyether.com" target="_blank">a music producer</a> type who saw some cover videos by The Soogs, thought, “I’d like to record those guys,” and offered up his services. The Soogs’ music has been described as “The Pixies meet The Partridge Family,” but it was only described that way once and it’s hard to remember who said it. 
            </p>
            <ScrollToButton
              id="little-miss-sparks-video"
              content="Watch Little Miss Sparks Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
        textRight={
          <div>
            <img src="/images/the-soogs-indie-pop-atlanta.jpg" alt="The Soogs Indie Pop Atlanta" />
          </div>
        }
      />
      <SingleColumnBlock
        centered
        text={
          <div>
            <img src="/images/the-soogs-logo.png" alt="The Soogs Indie Pop Atlanta" />
          </div>
        }
      />
      <PageWrapper color="charcole">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>"Level Terrace Appartment" EP out now</h3>
              <p>
                The second release by The Soogs.
              </p>
              <ScrollToButton
                id="little-miss-sparks-video"
                content="Watch Little Miss Sparks Video"
                style={{ marginBottom: "20px" }}
              />
              <h4>Download</h4>
              <p>
                Download in lossless or lossy format of your choice at <a href="https://thesoogs.bandcamp.com/album/level-terrace-apartment" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                Pelt us with fractions of pennies by streaming over your music preview platform of choice:<br />
                <a href="https://open.spotify.com/album/1CCJpawjJgiafgf7r3okS6?si=BrePu0_fT76nrTPCBZ98tA" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/level-terrace-apartment/1639263192" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/242565050" target="_blank">Tidal</a><br />
                <a href="https://amazon.com/music/player/albums/B0B99YZZ1N?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_DPGZxUxHuHImM9ugCU40b7hJY" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/345411917" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=FwCp_L2XZp8&list=OLAK5uy_mywyiLHMtXv1pUABsPXFfBPsKpxiXzInk" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/level-terrace-apartment-the-soogs/cn8ft5qxp13qb" target="_blank">Qobuz</a>
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/level-terrace-apartment-the-soogs.jpg" alt="Level Terrace Apartment EP by The Soogs" />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <h3>"Bully" LP</h3>
              <p>
                The first release by The Soogs.
              </p>
              <h4>Download</h4>
              <p>
                Download in lossless or lossy format of your choice at <a href="https://thesoogs.bandcamp.com/album/bully-2" target="_blank">Bandcamp</a>.
              </p>
              <h4>Stream</h4>
              <p>
                <a href="https://open.spotify.com/album/67vTYPRVEg6WM47IBdBCnJ?si=rAZ-lwbgRGiILU5BnzP_oQ" target="_blank">Spotify</a><br />
                <a href="https://music.apple.com/us/album/bully/1638971938" target="_blank">Apple Music</a><br />
                <a href="https://tidal.com/browse/album/242274367" target="_blank">Tidal</a><br />
                <a href="https://www.amazon.com/music/player/albums/B0B94LP2C8" target="_blank">Amazon Music</a><br />
                <a href="https://www.deezer.com/us/album/344941227" target="_blank">Deezer</a><br />
                <a href="https://www.youtube.com/watch?v=IDvg_tDra_g&list=OLAK5uy_nF_IJwq3dxLkh0M-J-pfZhIQwB5yl-8ec" target="_blank">YouTube Music</a><br />
                <a href="https://www.qobuz.com/us-en/album/bully-the-soogs/oyjmmt91khzob" target="_blank">Qobuz</a>
              </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/bully-the-soogs.jpg" alt="Bully LP by The Soogs" />
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/the-soogs-live.jpg" alt="The Soogs Live Events" />
          </div>
        }
        textRight={
          <div>
            <h2>See Us Live!</h2>
            <p>
              Follow us on <a href="https://www.facebook.com/the.soogs" target="_blank">Facebook</a> and <a href="https://tiktok.com/@thesoogs" target="_blank">TikTok</a> to find out about upcoming shows and releases.
            </p>
          </div>
        }
      />
      <PageWrapper color="charcole">
        <div id="little-miss-sparks-video">
            <VideoPlayer youtubeId='3FdfF6btyHE' />
        </div>
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Contact The Soogs</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
